<template>
  <r-page class="has-background-white" id="activity-detail">
    <template #page-header>
      <h2 class="page-title has-text-color-page-title">
        <b-icon
          class="cursor-pointer"
          icon="chevron-left"
          size="is-small"
          @click.native="$parent.close()"
        />
        Activity Detail
      </h2>
      <r-third-level-menu />
    </template>

    <template #action-bar>
      <div class="columns is-multiline is-mobile">
        <div
          class="column is-8-mobile is-2-desktop is-2-widescreen is-2-fullhd"
        >
          <b-datepicker
            placeholder="Click to select..."
            :data="selectedDate"
            v-model="selectedDate"
            @input="resetData"
          >
            <template v-slot:trigger>
              <b-button
                icon-left="calendar-today"
                class="has-text-black"
                type="is-white"
                icon-right="chevron-down"
              >
                {{ formatDate(selectedDate, 'DD MMMM YYYY') }}
              </b-button>
            </template>
          </b-datepicker>
        </div>

        <div
          class="column is-8-mobile is-3-desktop is-3-widescreen is-3-fullhd"
          v-if="currentUser.isAdmin"
        >
          <b-autocomplete
            v-model="name"
            :data="listUser"
            placeholder="Search User"
            field="fullName"
            :loading="isFetchingUserList"
            :check-infinite-scroll="true"
            @typing="getAsyncData"
            @select="selectUser"
            @infinite-scroll="getMoreAsyncData"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-left">
                  <div v-if="props.option.profilePictureUrl">
                    <img
                      class="profile-picture"
                      width="32"
                      :src="props.option.profilePictureUrl"
                    />
                  </div>
                  <div v-else>
                    <img
                      class="profile-picture"
                      v-if="props.option.gender === 'F'"
                      width="32"
                      src="/images/default-profile-picture-female.png"
                    />

                    <img
                      class="profile-picture"
                      v-else
                      width="32"
                      src="/images/default-profile-picture-male.png"
                    />
                  </div>
                </div>
                <div class="media-content">
                  {{ props.option.fullName }}
                  <br />
                  <small>
                    {{
                      props.option.positionName
                        ? props.option.positionName
                        : '-'
                    }}
                  </small>
                </div>
              </div>
            </template>
            <template #footer>
              <span
                v-show="pageUserList > lastPageUserList"
                class="has-text-grey"
              >
                Thats it! No more movies found.
              </span>
            </template>
          </b-autocomplete>
        </div>
      </div>

      <div class="columns is-multiline is-mobile">
        <div class="column is-12-mobile is-12-tablet is-4-desktop">
          <div class="box primary-gradient has-text-white">
            <p class="has-text-white has-text-weight-bold is-size-4 mb-4">
              Attendance
            </p>

            <div class="columns is-mobile">
              <div class="column is-6-mobile">
                <div class="box has-background-primary">
                  <div>
                    <p class="has-text-primary-light">Clock in/Out</p>
                    <p class="has-text-white" v-if="attendance.date">
                      {{
                        formatDate(
                          createUTCDate(attendance.firstCheckIn),
                          'HH:mm'
                        )
                      }}
                      /
                      {{
                        formatDate(
                          createUTCDate(attendance.lastCheckOut),
                          'HH:mm'
                        )
                      }}
                    </p>
                    <p class="has-text-white" v-else>-/-</p>
                  </div>
                </div>
              </div>
              <div class="column is-6-mobile">
                <div class="box has-background-primary">
                  <p class="has-text-primary-light">Working Time</p>
                  <p class="has-text-white" v-if="attendance.date">
                    {{
                      attendance.durationPerDay
                        ? formatDuration(attendance.durationPerDay)
                        : '-'
                    }}
                  </p>
                  <p v-else class="has-text-white">-</p>
                </div>
              </div>
            </div>
          </div>

          <div class="box">
            <b-tabs v-model="activeTab">
              <b-tab-item label="Activity">
                <div
                  class="activity-infinite-scroll"
                  v-infinite-scroll="loadMoreActivity"
                  infinite-scroll-disabled="busy"
                  infinite-scroll-distance="10"
                >
                  <div v-if="activities.length > 0">
                    <p>
                      Total Distance
                      <b-tag type="is-black" class="is-rounded">
                        {{ (totalActivityDistance / 1000).toFixed(2) }} km
                      </b-tag>
                    </p>

                    <div
                      v-for="(activityItem, index) in activities"
                      :key="activityItem.id"
                      class="mt-2 mb-4"
                    >
                      <div class="box">
                        <article class="media mb-2">
                          <figure class="media-left">
                            <div
                              class="number-container container has-background-primary has-text-weight-bold has-text-white is-rounded is-flex is-align-items-center is-justify-content-center"
                            >
                              {{ index + 1 }}
                            </div>
                          </figure>
                          <div class="media-content">
                            <div
                              class="tag has-background-grey-lighter mt-2 mb-2"
                            >
                              {{
                                activityItem.activityDuration
                                  ? formatDuration(
                                      activityItem.activityDuration
                                    )
                                  : '-'
                              }}
                            </div>
                            <div class="content">
                              <p>
                                <b-icon icon="google-maps" size="is-small" />
                                {{
                                  activityItem.locationIn
                                    ? activityItem.locationIn
                                    : 'Missing location'
                                }}
                              </p>
                              <p>
                                <b-icon icon="google-maps" size="is-small" />
                                {{
                                  activityItem.locationOut
                                    ? activityItem.locationOut
                                    : 'Missing location'
                                }}
                              </p>
                            </div>
                          </div>
                        </article>

                        <div class="columns is-multiline is-mobile">
                          <div
                            class="column is-12-mobile is-8-tablet is-7-desktop is-8-widescreen"
                          >
                            <div class="is-bordered-1 p-2 task-container">
                              <p class="has-text-grey-light">Task</p>
                              <p class="mt-2">
                                {{
                                  activityItem.attendanceActivityTypeDescription
                                }}
                              </p>
                            </div>
                          </div>

                          <div
                            class="column is-12-mobile is-4-tablet is-5-desktop is-4-widescreen is-flex"
                          >
                            <div class="is-bordered-1 is-border-radius-1 p-2">
                              <p class="has-text-grey-light">Activity</p>
                              <p class="mt-2">
                                {{
                                  formatDate(
                                    createUTCDate(
                                      activityItem.activityStartTime
                                    ),
                                    'HH:mm'
                                  )
                                }}
                                -
                                {{
                                  activityItem.activityEndTime
                                    ? formatDate(
                                        createUTCDate(
                                          activityItem.activityEndTime
                                        ),
                                        'HH:mm'
                                      )
                                    : 'on-going'
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="is-flex is-align-items-center is-justify-content-center container-no-data"
                  >
                    <p>No data found</p>
                  </div>
                </div>
              </b-tab-item>

              <b-tab-item label="Attendance">
                <div>
                  <div class="attendance-container" v-if="attendance.date">
                    <div
                      v-for="(attendanceItem, index) in attendance.data"
                      :key="attendanceItem.id"
                      class="mt-2 mb-4"
                    >
                      <div class="box">
                        <article class="media mb-2">
                          <figure class="media-left">
                            <div
                              class="number-container container has-background-primary has-text-weight-bold has-text-white is-rounded is-flex is-align-items-center is-justify-content-center"
                            >
                              {{ index + 1 }}
                            </div>
                          </figure>
                          <div class="media-content">
                            <div
                              class="tag has-background-grey-lighter mt-2 mb-2"
                              v-if="attendanceItem.countDuration"
                            >
                              {{
                                attendanceItem.countDuration
                                  ? formatDuration(attendanceItem.countDuration)
                                  : '-'
                              }}
                            </div>
                            <div class="content">
                              <p>
                                <b-icon icon="google-maps" size="is-small" />
                                {{
                                  attendanceItem.locationIn
                                    ? attendanceItem.locationIn
                                    : 'Missing location'
                                }}
                              </p>
                              <p>
                                <b-icon icon="google-maps" size="is-small" />
                                {{
                                  attendanceItem.locationOut
                                    ? attendanceItem.locationOut
                                    : 'Missing location'
                                }}
                              </p>
                            </div>
                          </div>
                        </article>

                        <div class="columns is-multiline is-mobile">
                          <div
                            class="column is-12-mobile is-6-tablet is-6-desktop"
                          >
                            <div class="is-bordered-1 is-border-radius-1 p-2">
                              <p class="has-text-grey-light">Clock In</p>
                              <p class="mt-2">
                                {{
                                  formatDate(
                                    createUTCDate(attendanceItem.startTime),
                                    'HH:mm'
                                  )
                                }}
                              </p>
                            </div>
                          </div>

                          <div
                            class="column is-12-mobile is-6-touch is-6-desktop"
                          >
                            <div class="is-bordered-1 is-border-radius-1 p-2">
                              <p class="has-text-grey-light">Clock Out</p>
                              <p class="mt-2">
                                {{
                                  attendanceItem.endTime
                                    ? formatDate(
                                        createUTCDate(attendanceItem.endTime),
                                        'HH:mm'
                                      )
                                    : 'on-going'
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="is-flex is-justify-content-center is-align-items-center container-no-data"
                  >
                    No data found
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
        <div
          class="column is-12-mobile is-12-tablet is-8-desktop is-fullwidth is-full-mobile"
        >
          <div class="map map-content" ref="mymap"></div>
        </div>
      </div>
    </template>
  </r-page>
</template>

<script>
import gmapsInit from '@/services/gmap'
import ApiService from '../../services/common/api.service'
import { mapActions, mapGetters } from 'vuex'
//import debounce from 'lodash.debounce'
//import attendance from '../../store/modules/attendance.module'
import moment from 'moment-timezone'
import {
  createUTCDate,
  getDuration,
  getLocation,
  showToast,
} from '../../services/util'
export default {
  props: {
    isSelf: Boolean,
    date: String,
    userId: Number,
    userName: String,
  },
  watch: {
    activities: function () {
      if (this.activeTab === 0) {
        this.initMap(this.activities)
      }
    },
    attendance: function () {
      if (this.activeTab === 1) {
        this.initMap(this.attendance.data, false)
      }
    },
    activeTab: function () {
      if (this.activeTab === 0) {
        this.initMap(this.activities)
      } else {
        this.initMap(this.attendance.data, false)
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
    }),
  },

  data() {
    return {
      activeTab: 0,
      attendance: {
        date: null,
        data: [],
        durationPerDay: null,
        firstCheckIn: null,
        lastCheckOut: null,
      },
      activities: [],
      totalActivityDistance: 0,

      selectedDate: null,

      employees: [],
      employeeSelected: null,
      employee_next_page_url: null,

      pageActivity: 0,
      lastPageActivity: 0,
      perPageActivity: 20,

      name: '',
      listUser: [],
      selectedUser: null,
      isFetchingUserList: false,
      pageUserList: 1,
      lastPageUserList: 1,
    }
  },
  methods: {
    ...mapActions({
      fetchAttendance: 'attendance/fetchAttendance',
    }),
    getDuration(startDate, endDate, unit = 'minute') {
      return getDuration(startDate, endDate, unit)
    },
    loadMoreActivity() {
      if (this.pageActivity < this.lastPageActivity) {
        this.loadActivities()
      }
    },
    clearAttendance() {
      this.attendance = {
        date: null,
        data: [],
        durationPerDay: null,
        firstCheckIn: null,
        lastCheckOut: null,
      }
    },
    clearActivity() {
      this.pageActivity = 0
      this.lastPageActivity = 0
      this.activities = []
      this.totalActivityDistance = 0
    },
    resetData() {
      this.clearActivity()
      this.clearAttendance()

      this.loadActivities(this.userId)
      this.loadAttendance(this.userId)
    },
    async loadAttendance(userId) {
      try {
        const start = moment(this.selectedDate).format('DD MMM YYYY')
        const end = moment(this.selectedDate).format('DD MMM YYYY')
        let param = {
          startDate: start,
          endDate: end,
        }
        if (!this.isSelf) {
          param.userId = userId
        }
        let data = await this.fetchAttendance(param)

        if (data.length > 0) {
          this.attendance = data[0]
        } else {
          this.clearAttendance()
        }
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message)
        } else {
          console.log(err)
        }
      }
    },
    async loadActivities(userId) {
      try {
        let param = {
          sortField: 'id',
          sortOrder: 'asc',
          filteringConditions: ['selectedDates'],
          selectedDuration: [
            moment(this.selectedDate).format('YYYY-MM-DD'),
            moment(this.selectedDate).format('YYYY-MM-DD'),
          ],
          perPage: this.perPageActivity,
          page: ++this.pageActivity,
        }

        if (!this.isSelf) {
          param.userId = userId
        }
        let response = await ApiService.get(
          '/api/space-roketin/attendance-activity',
          param
        )
        this.activities = [...this.activities, ...response.data.data]
        this.totalActivityDistance = response.data.meta.totalDistance
        this.pageActivity = response.data.meta.currentPage
        this.lastPageActivity = response.data.meta.lastPage
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message)
        } else {
          console.log(err)
        }
      }
    },
    formatDuration(durationPerDay) {
      let splitted = durationPerDay.split(':')
      let d = new Date()
      d.setHours(splitted[0])
      d.setMinutes(splitted[1])
      d.setSeconds(splitted[2])
      return d.getHours() + 'h ' + d.getMinutes() + 'm'
    },
    async getCurrentPosition() {
      try {
        let location = await getLocation()
        return {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        }
      } catch (err) {
        showToast(err.toString(), 'is-danger', 'is-bottom')
      }
    },
    async initMap(arrayData, displayRoute = true) {
      const google = await gmapsInit()
      let current = await this.getCurrentPosition()

      // Init map with center and zoom
      this.map = new google.maps.Map(this.$refs.mymap, {
        zoom: 10,
        center: current,
      })

      let coords = []
      for (let i = 0; i < arrayData.length; i++) {
        // find if coordinate already exist in array coords[]
        let exist = coords.findIndex(
          (c) =>
            c.lat === arrayData[i].locationLatIn &&
            c.lng === arrayData[i].locationLongIn
        )
        // if coordinate not exist, then push to array coords[]
        if (exist < 0) {
          coords.push({
            lat: arrayData[i].locationLatIn,
            lng: arrayData[i].locationLongIn,
          })
        }
      }

      let bounds = new google.maps.LatLngBounds()
      if (coords.length === 1) {
        // just draw a marker
        let latLng = {
          lat: parseFloat(coords[0].lat),
          lng: parseFloat(coords[0].lng),
        }
        this.makeMarker(google, latLng, '1', this.map)
        bounds.extend(latLng)
        this.map.fitBounds(bounds)
      } else {
        if (displayRoute) {
          // draw markers and routes
          for (let i = 0; i < coords.length - 1; i++) {
            bounds.extend({
              lat: parseFloat(coords[i].lat),
              lng: parseFloat(coords[i].lng),
            })

            bounds.extend( {
              lat: parseFloat(coords[i+1].lat),
              lng: parseFloat(coords[i+1].lng),
            })



            if (i === 0) {
              await this.calculateAndDisplayRoute(
                google,
                coords[i].lat,
                coords[i].lng,
                coords[i + 1].lat,
                coords[i + 1].lng,
                i + 1,
                true
              )
            } else {
              await this.calculateAndDisplayRoute(
                google,
                coords[i].lat,
                coords[i].lng,
                coords[i + 1].lat,
                coords[i + 1].lng,
                i + 1
              )
            }
          }
          this.map.fitBounds(bounds)
        }else {
          let bounds = new google.maps.LatLngBounds()
          for (let i = 0; i < coords.length ; i++) {
            let latLng = {
              lat: parseFloat(coords[i].lat),
              lng: parseFloat(coords[i].lng),
            }
            bounds.extend(latLng)
            this.makeMarker(google, latLng, (i+1).toString(), this.map)
          }

          this.map.fitBounds(bounds)

        }
      }
    },

    async calculateAndDisplayRoute(
      google,
      latStart,
      longStart,
      latEnd,
      longEnd,
      index,
      isFirstPoint
    ) {
      //const markerArray = []

      const directionsService = new google.maps.DirectionsService()
      const directionsRenderer = new google.maps.DirectionsRenderer()
      // Instantiate an info window to hold step text.
      //const stepDisplay = new google.maps.InfoWindow()

      directionsRenderer.setMap(this.map)

      try {
        let response = await directionsService.route({
          origin: new google.maps.LatLng(latStart, longStart),
          destination: new google.maps.LatLng(latEnd, longEnd),
          travelMode: google.maps.TravelMode.DRIVING,
        })

        new google.maps.DirectionsRenderer({
          map: this.map,
          directions: response,
          suppressMarkers: true,
        })

        var leg = response.routes[0].legs[0]
        // directionsRenderer.setDirections(response)

        if (isFirstPoint) {
          this.makeMarker(
            google,
            leg.start_location,
            index.toString(),
            this.map
          )
          this.makeMarker(
            google,
            leg.end_location,
            (index + 1).toString(),
            this.map
          )
        } else {
          this.makeMarker(
            google,
            leg.end_location,
            (index + 1).toString(),
            this.map
          )
        }
      } catch (err) {
        showToast('Failed to plot route in map', 'is-primary', 'is-bottom')
        console.log('Directions request failed due to ' + status + '--' + err)
      }
    },

    makeMarker(google, position, title, map) {
      new google.maps.Marker({
        position: position,
        map: map,
        label: title,
      })
    },

    createUTCDate(dateTime) {
      let result = createUTCDate(dateTime)
      return result
    },

    formatDate(date, format) {
      return moment(date).format(format)
    },
    selectUser(option) {
      this.selectedUser = option
      this.resetData()
    },
    // // You have to install and import debounce to use it,
    // // it's not mandatory though.
    getAsyncData(name) {
      // String update
      if (this.name !== name) {
        this.name = name
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
      }
      // String cleared
      if (!name.length) {
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
        return
      }
      // Reached last page
      if (this.pageUserList > this.lastPageUserList) {
        return
      }
      this.isFetchingUserList = true
      ApiService.get('/api/space-roketin/user/v3', {
        userId: this.selectedUser ? this.selectedUser.id : this.userId,
        search: this.name,
        page: this.pageUserList,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.listUser.push(item))

          this.pageUserList++
          this.lastPageUserList = response.data.meta.lastPage
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetchingUserList = false
        })
    },
    getMoreAsyncData() {
      this.getAsyncData(this.name)
    },
  },
  async mounted() {
    this.selectedDate = new Date(this.date)
    this.name = this.userName
    await this.loadActivities(this.userId)
    await this.loadAttendance(this.userId)
    //await this.initMap(this.activities)

    this.createUTCDate('2021-11-16 03:52:04')
  },
}
</script>
